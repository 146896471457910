<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import type { GetBroadcastMessagesForMobileResponse_VideoBroadcastForMobile as VideoBroadcast } from '~/types/api/Psrm.Mobile.Api.Contract.IvrWeb';

const $emit = defineEmits<{
    (e: 'play'): void;
}>();

const $props = withDefaults(defineProps<{
    modelValue: VideoBroadcast;
    acknowledged: boolean;
}>(), {});

function onPlay() {
    $emit('play');
}
</script>

<template>
    <div class="card">
        <div class="card-body">
            <div class="card-title d-flex align-items-center">
                <h2 class="h6">{{ $props.modelValue.title }}</h2>
                <span
                    v-if="$props.modelValue.required"
                    class="badge badge-danger ms-auto"
                >
                    Required
                </span>
            </div>
            <div class="d-flex align-items-center">
                <span v-if="$props.acknowledged" class="badge badge-success d-flex gap-2 align-items-center">
                    <FontAwesomeIcon icon="circle-check" />
                    <span>Watched</span>
                </span>
                <div class="flex-grow-1"></div>
                <BButton
                    variant="outline-dark"
                    size="sm"
                    class="d-flex gap-2 align-items-center"
                    @click="onPlay"
                >
                    <FontAwesomeIcon icon="play" />
                    <span>Play</span>
                </BButton>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
// delete me if empty
</style>
