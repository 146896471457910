<!-- eslint-disable vue/multi-word-component-names -->
<script setup lang="ts">
import type { GetBroadcastMessagesForMobileResponse_AudioBroadcastForMobile as AudioBroadcast } from '~/types/api/Psrm.Mobile.Api.Contract.IvrWeb';

const $emit = defineEmits<{
    (e: 'acknowledge'): void;
}>();

const $props = withDefaults(defineProps<{
    modelValue: AudioBroadcast;
    acknowledged: boolean;
}>(), {});

function onAcknowledgeClick() {
    $emit('acknowledge');
}
</script>

<template>
    <div class="card">
        <div class="card-body">
            <div class="card-title">
                <h2 class="h6">{{ $props.modelValue.name }}</h2>
            </div>
            <div class="text-prewrap">{{ $props.modelValue.message }}</div>
            <div class="text-end">
                <BButton
                    v-if="!$props.acknowledged"
                    variant="outline-dark"
                    size="sm"
                    @click="onAcknowledgeClick"
                >
                    <FontAwesomeIcon icon="circle-check" />
                    Acknowledge
                </BButton>
                <span v-else class="badge badge-success">
                    <FontAwesomeIcon icon="circle-check" />
                    Acknowledged
                </span>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
// delete me if empty
</style>
