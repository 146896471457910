<script setup lang="ts">
export type IconType = 'broadcast' | 'dashboard' | 'person' | 'warning';

const $props = withDefaults(defineProps<{
    icon: IconType;
    isActive: boolean;
}>(), { isActive: false });
</script>

<template>
    <div class="image-icon" :style="{ maskImage: `url(/icons/${$props.icon}${$props.isActive ? '-active' : ''}.svg)` }"></div>
</template>

<style scoped lang="scss">
.image-icon {
    --size: 1.5rem;

    background: currentcolor;
    mask-size: contain;
    mask-repeat: no-repeat;
    height: var(--size);
    width: var(--size);
}
</style>
